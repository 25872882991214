import React, { useState, useEffect } from "react";
import styled from "styled-components";
import C9 from "../images/logos/Cloud9.png";
import DWG from "../images/logos/DWGKIA.png";
import DTN from "../images/logos/DetonatioNGaming.png";
import IE from "../images/logos/InfinityEsports.png";
import MAD from "../images/logos/MADLions.png";
import PNG from "../images/logos/PaiNGaming.png";
import PGG from "../images/logos/PentanetGG.png";
import PSG from "../images/logos/PSGTalon.png";
import RNG from "../images/logos/RoyalNeverGiveUp.png";
import UOL from "../images/logos/UnicornsOfLove.png";
import IW from "../images/logos/IstanbulWildcats.png";

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  border-left: 1px solid white;
  border-right: 1px solid white;
`;

const Column = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 5px;
`;

const Cell = styled.div`
  border-top: 1px solid white;
  padding: 5px;
  justify-content: center;

  :last-child {
    border-bottom: 1px solid white;
  }
`;

const LogoContainer = styled.div`
  img {
    height: 75px;
    width: auto;
  }
`;

const Winners = (teams) => {
  const group = teams.teams;

  return (
    <Cell>
      {group.map((team) => {
        return (
          <LogoContainer key={team.name}>
            <img src={team.thumb} alt={`${team.name} Thumb`} />
          </LogoContainer>
        );
      })}
    </Cell>
  );
};

export default function Groups() {
  const [stage, setStage] = useState("groups");
  const [submissions, setSubmissions] = useState(null);
  const groupsAnswers = [
    [RNG, PGG, UOL],
    [MAD, PSG, PNG, IW],
    [DWG, C9, DTN, IE],
  ];

  useEffect(() => {
    loadPredictions();
  }, []);

  const loadPredictions = async () => {
    const body = {
      stage: "results",
    };
    const res = await fetch(`/.netlify/functions/predictions`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    const text = await res.json();

    setSubmissions(text);
  };

  if (!submissions) {
    return null;
  }

  return (
    <Container>
      {submissions.map((submission) => {
        if (submission[stage]) {
          return (
            <Column key={submission.name}>
              <Cell>{submission.name}</Cell>
              <Winners teams={submission[stage][0]} />
              <Winners teams={submission[stage][1]} />
              <Winners teams={submission[stage][2]} />
            </Column>
          );
        }
      })}
      <Column>
        <Cell>Winners</Cell>
        {stage === "groups" &&
          groupsAnswers.map((teams, i) => {
            return (
              <Cell key={`${i}-teams`}>
                {teams.map((team, i) => {
                  return (
                    <LogoContainer key={`${i}-thumb-image`}>
                      <img src={team} alt={`Winner Thumb`} />
                    </LogoContainer>
                  );
                })}
              </Cell>
            );
          })}
        {stage === "rumble" &&
          groupsAnswers.map((teams, i) => {
            return (
              <Cell key={`${i}-teams`}>
                {teams.map((team, i) => {
                  return (
                    <LogoContainer key={`${i}-thumb-image`}>
                      <img src={team} alt={`Winner Thumb`} />
                    </LogoContainer>
                  );
                })}
              </Cell>
            );
          })}
      </Column>
    </Container>
  );
}
