import * as React from "react";
import styled from "styled-components";
import Results from "../components/Results";

const Main = styled.main`
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  > * {
    grid-column: 2;
  }
`;

// markup
const RumblePage = () => {
  return (
    <Main>
      <Results />
    </Main>
  );
};

export default RumblePage;
